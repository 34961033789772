<template>
<div class="content pt-0">

  <validation-observer ref="VForm">
    <b-form @submit.prevent="doSubmit" class="card">
      <div class="card mb-1">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Detail Rujukan Keluar</h6>
        </div>
        <div class="card-body p-3">
          <div class="alert alert-info"><span class="font-weight-semibold">Catatan Penolakan:</span> Tidak tersedia fasilitas yang memadai</div>
          <div class="mb-2">
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="table-secondary">
                  <th colspan="4">Informasi Pasien {{ $parent.isDetail }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>No. RM</h4>
                      <p>{{row.aranj_no_rm_asal||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Nama Pasien </h4>
                      <p>{{row.aranj_nama||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Kontak</h4>
                      <p>{{row.aranj_kontak||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="result_tab">
                      <h4>Alamat</h4>
                      <p>{{row.aranj_alamat||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tempat, Tanggal Lahir</h4>
                      <p>{{row.aranj_tempat_lahir||"-"}}, {{row.aranj_dob | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Jenis Kelamin</h4>
                      <p>{{row.aranj_jenis_kelamin == 1 ? "Laki-Laki":"Perempuan"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>No. Kartu JKN</h4>
                      <p>{{row.aranj_no_kartu||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>NIK</h4>
                      <p>{{row.aranj_nik||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-2">
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="table-secondary">
                  <th colspan="4">Informasi Rujukan</th>
                </tr>
              </thead>
              <tr>
                <td>
                  <div class="result_tab row">
                    <div class="col-md-5">
                      <h4>No. Rujukan</h4>
                      <p>{{row.aranj_no_rujukan||"-"}}</p>
                    </div>
                    <div class="col-md-5">
                      <h4>No. Rujukan Sisrute</h4>
                      <p>{{row.aranj_no_rujukan_sisrute||"-"}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Faskes Tujuan</h4>
                    <!-- <p>{{row.aranj_faskes_rujuk_sisrute_text||"-"}}</p> -->
                    <v-select class="diagnosa-section select-paging" placeholder="Cari Nama Faskes" v-model="row.aranj_faskes_rujuk_sisrute" @input="inpMasterFaskesSisrute($event)" @search="getMasterFaskesSisrute($event)" :options="mFaskesSisrute" label="NAMA" value="KODE" :clearable="true" :reduce="v=>v.KODE" :disabled="$parent.isDetail">
                      <template slot="no-options">
                        <span></span>
                      </template>
                    </v-select>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Rujukan</h4>
                    <!-- JENIS RUJUKAN -->
                    <p>Rawat Darurat/Inap</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Alasan</h4>
                    <p>
                      {{getConfigDynamic(Config.mr.alasanRujukan,row.aranj_alasan)||"-"}}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Alasan Lainnya</h4>
                    <p>{{row.aranj_alasan_lainnya||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Status</h4>
                    <div class="badge badge-primary mb-1" v-if="row.aranj_status == 'B'">
                      Buat Rujukan BPJS
                    </div>
                    <div class="badge badge-warning mb-1" v-if="row.aranj_status == 'M'">
                      Menunggu
                    </div>
                    <div class="badge badge-warning mb-1" v-else-if="row.aranj_status == 'MR'">
                      Menunggu Respon
                    </div>
                    <div class="badge badge-dark mb-1" v-else-if="row.aranj_status == 'A'">
                      Diterima
                    </div>
                    <div class="badge badge-success mb-1" v-else-if="row.aranj_status == 'T'">
                      Ditolak
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Tanggal Rujukan</h4>
                    <p>{{row.aranj_tanggal_rujuk | moment("DD MMMM YYYY, HH:mm")}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Dokter Perujuk</h4>
                    <v-select placeholder="Pilih Dokter Perujuk" v-model="row.aranj_dokter" :options="mDokter" label="text" :disabled="!isResend" :reduce="v=>v.value" />
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Diagnosa</h4>
                    <p>{{row.aranj_diagnosa||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="mb-2">
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="table-secondary">
                  <th colspan="4">Kondisi Umum</th>
                </tr>
              </thead>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Anamnesis dan Pemeriksaan Fisik</h4>
                    <p>{{row.aranj_kondisi_umum||"-"}}</p>
                    <!-- nnoted -->
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Kesadaran</h4>
                    <v-select placeholder="Pilih Kesadaran"
                      v-model="row.aranj_kesadaran"
                      @input="updateRow(row)"
                      :disabled="$parent.isDetail"
                      :options="mrKesadaran" label="label" :clearable="true"
                      :reduce="v=>String(v.value)">
                      <template slot="selected-option"
                          slot-scope="option">
                        <span v-b-tooltip.hover :title="option.label">{{ option.label }}</span>
                      </template>
                      <template slot="option" slot-scope="option">
                        <span v-b-tooltip.hover :title="option.label">{{ option.label }}</span>
                      </template>
                    </v-select>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Tekanan Darah</h4>
                    <p v-if="row.aranj_tekanan_darah_min && row.aranj_tekanan_darah_max">{{row.aranj_tekanan_darah_min}}/{{row.aranj_tekanan_darah_max}}</p>
                    <span v-else> - </span>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Frekuensi Nadi</h4>
                    <p>{{row.aranj_frekuensi_nadi||"-"}} x/menit</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Suhu</h4>
                    <p>{{row.aranj_suhu||-""}}&deg;C</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Pernafasan</h4>
                    <p>{{row.aranj_pernafasan||"-"}}x/menit</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Nyeri</h4>
                    <!--Skala Nyeri 0. Tidak Nyeri, 1. Ringan, 2. Sedang, 3. Berat -->

                    <v-select placeholder="Pilih Nyeri"
                      v-model="row.aranj_nyeri"
                      @input="updateRow(row)"
                      :disabled="$parent.isDetail"
                      :options="mrNyeri" label="value" :clearable="true"
                      :reduce="v=>String(v.value)">
                      <template slot="selected-option"
                          slot-scope="option">
                        <span v-b-tooltip.hover :title="option.label">{{ option.label }}</span>
                      </template>
                      <template slot="option" slot-scope="option">
                        <span v-b-tooltip.hover :title="option.label">{{ option.label }}</span>
                      </template>
                    </v-select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Keadaan Umum</h4>
                    <p>{{row.aranj_keadaan_umum||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Alergi</h4>
                    <p>{{row.aranj_alergi||"-"}}</p>
                  </div>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
          <div>
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="table-secondary">
                  <th colspan="4">Penunjang</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <div class="d-flex align-items-baseline">
                        <h4>Laboratorium</h4>
                        <a v-if="row.aranj_laboratorium_file" :href="row.aranj_laboratorium_file" target="_blank" class="text-underline ml-2">
                          <i class="icon-download mr-1"></i>
                          <span>Unduh File Terlampir</span>
                        </a>
                      </div>
                      <p>{{row.aranj_laboratorium||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <div class="d-flex align-items-baseline">
                        <h4>Radiologi</h4>
                        <a v-if="row.aranj_radiologi_file" :href="row.aranj_radiologi_file" target="_blank" class="text-underline ml-2">
                          <i class="icon-download mr-1"></i>
                          <span>Unduh File Terlampir</span>
                        </a>
                      </div>
                      <p>{{row.aranj_radiologi||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Terapi</h4>
                      <p>{{row.aranj_terapi||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan</h4>
                      <p>{{row.aranj_tindakan||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Persetujuan Rujukan Keluar</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label> Catatan</label>
                <template v-if="!isDetail">
                  <textarea v-model="row.aranj_catatan" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false" value=""></textarea>

                  <VValidate name="Catatan" v-model="row.aranj_catatan" :rules="{required: 1, min: 2, max:128}" />
                </template>
                <template v-else>
                  <p>{{row.aranj_catatan||"-"}}</p>
                </template>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back" class="btn btn-transparent ml-auto mr-2">Back</button>
            <button v-if="!isDetail" class="btn btn-primary" type="submit">Kirim <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </div>
    </b-form>
  </validation-observer>
  <!-- /dashboard content -->
</div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  props: {
    row: Object,
    mrValidation: Object,
    Config: Object
  },
  computed: {
    isDetail() {
      return this.$route.query.isDetail ? true : false
    },
    isResend() {
      return this.$route.query.isResend ? true : false
    }
  },
  data() {
    return {
      mDokter: [],
      mKesadaran: [],
      mFaskesSisrute: [],
      
      mrKesadaran: [],
      mrNyeri: [],
    }
  },
  methods: {
    getMasterFaskesSisrute: _.debounce(function (e) {
      if (e) {
        let data = {
          search: e,
          type: 'select-faskes-sisrute'
        }
        Gen.apiRest(
          "/do/" + 'RanapRujukan', {
            data: data
          }, "POST"
        ).then(res => {
          this.mFaskesSisrute = res.data
        })
      }
    }, 1000),
    inpMasterFaskesSisrute(e) {
      if (e) {
        let idx = this.mFaskesSisrute.findIndex(x => x.KODE == e)
        this.row.aranj_faskes_rujuk_sisrute = this.mFaskesSisrute[idx]['KODE']
        this.row.aranj_faskes_rujuk_sisrute_text = this.mFaskesSisrute[idx]['NAMA']

        setTimeout(() => { 
          this.updateRow(this.row)
        }, 1000)
      }

    },

    updateRow(data){
      Gen.apiRest(
        "/do/" + 'RanapRujukan', {
          data: { ...data, type: 'update-data', id_registrasi: this.$route.params.pageSlug },
        },
        "POST"
      )
    },
    toValidate(val) {
      return {
        ...val
      }
    },
    back() {
      this.$router.back()
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    apiGetMaster() {
      Gen.apiRest(
        "/get/" + this.$parent.modulePage +
        '/master', {}
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    doSubmit() {
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$parent.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
              }, 500)
            }
          })
        }

        if (success) {
          this.$parent.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'

              this.$parent.loadingOverlay = true
              Gen.apiRest(
                "/do/" + 'RanapRujukan', {
                  data: data
                },
                "POST"
              ).then(res => {
                this.$parent.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                }).then(result => {
                  if (result.value) {
                    this.$router.push({
                        name: this.$route.name
                      })
                      .catch(() => {})
                  }
                })
              }).catch(err => {
                this.$parent.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.$parent.doSetAlertForm(err)
              })

            }
          })
        }
      })
    }
  },
  mounted() {
    this.apiGetMaster()

    setTimeout(() => {
      this.getMasterFaskesSisrute(this.row.faskes_rujuk_sisrute||"A")
    },1000)
  },
}
</script>
